/**
 * Bootstrap overrides.
 */

:root {
  --bs-body-color: #FFF;
  --bs-primary: #FFF;
  --bs-secondary: #FFF;
}

a {
  color: var(--bs-primary);
}

