/**
 * Custom theming.
 */
:root {
  --co-link: #FFF;
  --co-link-state: #F8FFC3;
  --co-accent-1: #42D392;
  --co-accent-2: #647EFF;
  --co-text-select-fg: #7372A9;
  --co-text-select-bg: #FFF;
}

